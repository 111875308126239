<template>
<footer class="footer footer--main">
    <div class="footer__logo">
        <ebba-logo></ebba-logo>
        <span>Ebba is developed and maintained <br/>by Techpie och Your Monday.</span>
    </div>
    <div class="footer__content contact">
        <h5 class="footer__title">Contact us</h5>
        <span><a href="#">hello@ebba.dev</a></span>
    </div>
    <div class="footer__content about">
        <h5 class="footer__title">About</h5>
        <span>Kungsgatan 4, Gothenburg</span>
        <span>VAT: SE556688112201</span>
    </div>
    <div class="footer__content privacy">
        <h5 class="footer__title">Your integrity</h5>
        <span><a @click.prevent="PrivacyModal">Privacy policy</a></span>
    </div>
</footer>
</template>
<script>
import ModalMixin from '@/mixins/modal'

export default {
components: {
    EbbaLogo: () => import("@/components/ebba/ebba-logo"),
},
mixins: [ModalMixin],
data() {
    return {};
},
mounted() {},
};
</script>
<style lang="scss">
.footer {
    color: #383838;
    background-color: var(--accent-color-3);
    font-size: .857rem;
    line-height: 1.25;
    user-select: none;

    &__logo {
        svg {
            width: 80px;
        }
    }
    &__title {
        font-weight: 600;
        font-size: .929rem;
        margin-bottom: .3rem;
    }
    span {
        display: block;
    }
    a {
        cursor: pointer;
    }
    a:hover {
        text-decoration: underline;
    }
    @include max-md{
        padding-top: var(--padding-large);
        padding-bottom: var(--padding-large);
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        text-align: center;
        &__logo {
            padding-bottom: 3rem;
            margin: 0 3rem 3rem;
            border-bottom: 1px solid #fff;
        }
        svg {
            margin: 0 auto 1rem auto;
        }
        &__content {
            margin-bottom: 1.5rem;
        }
    }
    @include md {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        padding: 2rem 2.75rem;
        &__logo {
            flex: 1;
        }
        &__content {
            flex: 1;
        }
    }
}
</style>
